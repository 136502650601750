









import {Vue, Component, Watch} from "vue-property-decorator";
import IssueGroupFooterType from "./indexType";
import FooterInput from "@/views/Group/IssueGroup/components/FooterInput/index.vue";
import FooterTime from "@/views/Group/IssueGroup/components/FooterTime/index.vue";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";

@Component({name: "IssueGroupFooter",components:{ FooterInput,FooterTime }})
export default class IssueGroupFooter extends Vue implements IssueGroupFooterType{
    reset = false
    money=""
    people=""
    message=""

    handleGetData(e:any){
        switch (e.other.type) {
            case "money":
                IssueGroupStore.SetIssueGroupPrice(e.data)
                break
            case "number":
                IssueGroupStore.SetIssueGroupPeople(e.data)
                break
            case "message":
                IssueGroupStore.SetIssueGroupMessage(e.data)
                break
        }
    }

    get getIssueGroupClear(){ return IssueGroupStore.getIssueGroupClear }
    @Watch("getIssueGroupClear")
    changeClear(newVal:boolean){
        this.reset = true
        let time = setTimeout(()=>{
            this.reset = false
            clearTimeout( time )
        },1000)
    }

    get getUpDateState(){ return IssueGroupStore.getIssueGroupUpDateState }
    @Watch("getUpDateState")
    changeUpDate(newVal:boolean){
        if ( newVal ){
            this.money = IssueGroupStore.getIssueGroupPrice as string
            this.people = IssueGroupStore.getIssueGroupPeople as string
            this.message = IssueGroupStore.getIssueGroupMessage as string
        }
    }
}
