










import {Vue, Component, Prop, Watch, Emit} from "vue-property-decorator";
import ContTextareaType from "@/views/Group/IssueGroup/components/ContTextarea/indexType";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";

@Component({name: "ContTextarea"})
export default class ContTextarea extends Vue implements ContTextareaType{
    value = ""

    get getIssueGroupClear(){ return IssueGroupStore.getIssueGroupClear }
    @Watch("getIssueGroupClear")
    changeClear(newVal:boolean){
        this.value = ""
    }

    @Prop(String)
    val!:string
    @Watch("val")
    changeVal(newVal:any){
        this.value = newVal
    }

    @Watch("value")
    changeValue(newVal:string){
        IssueGroupStore.SetIssueGroupContent(newVal)
        // this.pullData(newVal)
    }
    @Emit("pullData")
    pullData(data:any){
        return data
    }

    get getUpDateState(){ return IssueGroupStore.getIssueGroupUpDateState }
    @Watch("getUpDateState")
    changeUpDate(newVal:boolean){
        if ( newVal ){
            this.value = IssueGroupStore.getIssueGroupContent
        }
    }
}
