





import {Vue, Component, Prop, Ref, Watch, Emit} from "vue-property-decorator";
import FooterSelTimeType from "./indexType";
import moment from "moment";
import {isStopScroll} from "@/util/Adaptive";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";
import {IndexStore} from "@/store/modules/Index";

@Component({name: "FooterSelTime"})
export default class FooterSelTime extends Vue implements FooterSelTimeType{
    minDate = new Date( moment().subtract(6,"month").format("YYYY-MM-DD") )
    maxDate = new Date( moment().add(6,"M").format("YYYY-MM-DD") )
    show = false

    @Ref("timeBox")
    box!:any

    handleClose(){
        this.pullShow(false)
    }

    onConfirm(e:any){
        let date = moment(e).format("YYYY-MM-DD HH:mm:ss")
        if ( this.getState == "start" ){
            IssueGroupStore.SetIssueGroupStartTime( date )
        }else{
            IssueGroupStore.SetIssueGroupEndTime( date )
        }
        this.pullShow(false)
        this.pullData(moment(e).format("YYYY年 MM月 DD日"))
    }

    @Prop(Date)
    min!:any
    get getMinDate(){ return this.min || this.minDate }

    @Prop(Date)
    max!:any
    get getMaxDate(){ return this.max || this.maxDate }

    @Prop(String)
    state!:string
    get getState(){ return this.state || '' }

    // 默认时间
    @Prop(Date)
    defaultDate!:any
    get getDefaultDate(){ return this.defaultDate }

    @Prop(Boolean)
    hide!:boolean
    get getHide(){ return this.hide }
    @Watch("getHide")
    changeHideAndPull(newVal:boolean){
        this.show = newVal
        if ( newVal ){
            IndexStore.SetIncidentBool(true)
        }else{
            IndexStore.SetIncidentBool(false)
        }
    }

    @Emit("pullShow")
    pullShow(data:any){
        return data
    }
    @Emit("pullData")
    pullData(data:any){
        return data
    }

}
