

















import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import FooterTimeType from "./indexType";
import FooterSelTime from "@/views/Group/IssueGroup/components/FooterSelTime/index.vue";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store/index";
import moment from "moment";

@Component({name: "FooterTime",components:{ FooterSelTime }})
export default class FooterTime extends Vue implements FooterTimeType{
    rightIcon = require("@/assets/icon/Basics/Right.png")
    value = ""
    show=false
    minStart:any=""
    defaultDate = new Date()

    handleGetPull(e:any){
        // if ( e && this.getState == "end" && IssueGroupStore.getIssueGroupStartTime.length ){
        //     this.minStart = new Date( IssueGroupStore.getIssueGroupStartTime )
        // }
        this.show = e;
    }

    handleGetData(newVal:string){
        if ( newVal.length ){ this.value = newVal }
    }

    @Prop(String)
    title!:string
    get getTitle(){ return this.title || '标题' }

    @Prop(String)
    place!:string
    get getPlace(){ return this.place || '请输入内容' }

    @Prop(String)
    state!:string
    get getState(){ return this.state || 'start' }

    get getIssueGroupClear(){ return IssueGroupStore.getIssueGroupClear }
    @Watch("getIssueGroupClear")
    changeClear(newVal:boolean){
        this.value = ""
    }

    get getStartTime(){
        return IssueGroupStore.getIssueGroupStartTime
    }
    @Watch("getStartTime",{ deep:true })
    changeStartTime(newVal:string){
        if ( newVal.length ){
            if ( this.state == "end" ) this.minStart = new Date( IssueGroupStore.getIssueGroupStartTime );
        }
    }

    get getUpDateState(){ return IssueGroupStore.getIssueGroupUpDateState }
    @Watch("getUpDateState")
    changeUpDate(newVal:boolean){
        if ( newVal ){
            if ( this.state == 'start' ){
                this.value=IssueGroupStore.getIssueGroupStartTime && moment(IssueGroupStore.getIssueGroupStartTime).format("YYYY年 MM月 DD日")
                let time = setTimeout(()=>{
                    this.defaultDate = IssueGroupStore.getIssueGroupStartTime && new Date(IssueGroupStore.getIssueGroupStartTime) || new Date()
                    clearTimeout(time)
                },800)
            }else{
                this.value=IssueGroupStore.getIssueGroupEndTime && moment(IssueGroupStore.getIssueGroupEndTime).format("YYYY年 MM月 DD日")
                let time = setTimeout(()=>{
                    this.defaultDate = IssueGroupStore.getIssueGroupEndTime && new Date(IssueGroupStore.getIssueGroupEndTime) || new Date()
                    this.minStart = IssueGroupStore.getIssueGroupStartTime && new Date(IssueGroupStore.getIssueGroupStartTime);
                    clearTimeout(time)
                    },800)
            }

            this.$forceUpdate()
        }
    }
}
