



















import {Vue, Component} from "vue-property-decorator";
import IssueGroupType from "./IssueGroup";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import IssueGroupTitle from "@/views/Group/IssueGroup/components/Title/index.vue"
import IssueGroupCont from "@/views/Group/IssueGroup/components/Cont/index.vue"
import IssueGroupFooter from "@/views/Group/IssueGroup/components/Footer/index.vue";
import {IssueGrouping, updateGrouping} from "@/views/Group/IssueGroup/Server";
import {updateFun} from "@/views/Group/IssueGroup/util";

@Component({name: "IssueGroup",components:{ HeadTop,IssueGroupTitle,IssueGroupCont,IssueGroupFooter }})
export default class IssueGroup extends ZoomPage implements IssueGroupType{
    title="发布"
    updateState = false
    updateData:any = {}

    mounted(){
       this.loadingShow = false
    }

    activated(){
        if (this.$route.query['state'] && this.$route.query['state'] == 'update'){
            this.title = "修改"
            let data = this.$route.query['data'] && JSON.parse( this.$route.query['data'] as string ) || {}
            updateFun(data)
            this.updateData = data
            this.updateState = true
        }else{
            this.title = "发布"
            this.updateState = false
        }
    }

    handleClickBtn(){
        if ( this.updateState ){
            updateGrouping(this.updateData).then(res=>{
                this.$router.go(-1)
            })
        }else{
            IssueGrouping().then(res=>{
                this.$router.go(-1)
            }).catch(cat=>cat)
        }
    }
}
