










import {Vue, Component, Watch, Emit, Prop} from "vue-property-decorator";
import IssueGroupTitleType from "./indexType";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";

@Component({name: "IssueGroupTitle"})
export default class IssueGroupTitle extends Vue implements IssueGroupTitleType{
    value = ""

    get getIssueGroupClear(){ return IssueGroupStore.getIssueGroupClear }
    @Watch("getIssueGroupClear")
    changeClear(newVal:boolean){
        this.value = ""
    }

    @Prop(String)
    val!:string
    @Watch("val")
    changeVal(newVal:any){
        this.value = newVal
    }

    @Watch("value")
    changeValue(newVal:string){
        IssueGroupStore.SetIssueGroupTitle(newVal)
        // this.pullData(newVal)
    }
    @Emit("pullData")
    pullData(data:any){
        return data
    }

    get getUpDateState(){ return IssueGroupStore.getIssueGroupUpDateState }
    @Watch("getUpDateState")
    changeUpDate(newVal:boolean){
        if ( newVal ){
            this.value = IssueGroupStore.getIssueGroupTitle
        }
    }
}
