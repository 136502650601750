






























import {Vue, Component, Watch} from "vue-property-decorator";
import ContUpFileType from "./indexType";
import {Toast} from "vant";
import {upPhotos} from "@/views/Group/IssueGroup/Server";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";
import { ImagePreview } from 'vant';

@Component({name: "ContUpFile"})
export default class ContUpFile extends Vue implements ContUpFileType{
    UpIcon = require("@/views/Group/Img/add.png");
    fileList: any[] = [];
    imgList:string[] = []
    updateState = true

    activated(){
        if (this.$route.query['state'] && this.$route.query['state'] == 'update'){
            this.updateState = false
        }else{
            this.updateState = true
        }
    }

    handleRead(e:any){
        if ( !e ){
            Toast("请选择上传图片")
            return;
        }
        let List:any[] = [],
            fileList:any[] = []
        if ( !Array.isArray( e ) ){
            List = [e.content];
            fileList = [e]
        } else{
            e.forEach((item,index)=>{
                List.push(item.content);
                fileList.push(item)
            });
        }
        this.fileList = this.fileList.concat(List)
        if ( fileList.length ){
            Toast.loading({ duration: 0,forbidClick: true,message: '上传中~', })
            upPhotos(fileList).then(res=>{
                Toast.clear()
                this.imgList = this.imgList.concat(res)
            })
        }else{
            Toast("请选择上传图片")
            return;
        }
    }

    handleRemove(index:number){
        try{
            // this.fileList.splice( index,1 )
            this.imgList.splice(index,1)
        }catch (e) {
            console.info("删除图片失败")
        }
    }

    handlePreview(index:number){
        ImagePreview({
            images:this.imgList,
            startPosition:index
        })
    }

    handleAfterRead(e: any): void {
        if ( e.length <= 0 ){
            Toast("请选择上传图片")
            return;
        }
        if ( this.fileList.length ){
            Toast.loading({ duration: 0,forbidClick: true,message: '上传中~', })
            upPhotos(this.fileList).then(res=>{
                Toast.clear()
                this.imgList = res
            })
        }else{
            Toast("请选择上传图片")
            return;
        }
    }

    handleRemoveList(e: any, keyData: any): void {
        let { index } = keyData
        try{
            this.fileList.splice( index,1 )
            this.imgList.splice(index,1)
        }catch (e) {
            console.info("删除图片失败")
        }
    }

    get getIssueGroupClear(){ return IssueGroupStore.getIssueGroupClear }
    @Watch("getIssueGroupClear")
    changeClear(newVal:boolean){
        this.imgList = []
        this.fileList = []
    }

    @Watch("imgList",{ deep:true })
    changeImgList(newVal:string[]){
        IssueGroupStore.SetIssueGroupImgList(newVal)
    }

    get getUpDateState(){ return IssueGroupStore.getIssueGroupUpDateState }
    @Watch("getUpDateState")
    changeUpDate(newVal:boolean){
        if ( newVal ){
            this.fileList = IssueGroupStore.getIssueGroupImgList
            this.imgList = IssueGroupStore.getIssueGroupImgList
        }
    }
}
