















import {Vue, Component, Emit, Watch} from "vue-property-decorator";
import IssueGroupContType from "./indexType";
import ContTextarea from "@/views/Group/IssueGroup/components/ContTextarea/index.vue";
import ContUpFile from "@/views/Group/IssueGroup/components/ContUpFile/index.vue";
import {IssueGroupStore} from "@/views/Group/IssueGroup/Store";

@Component({name: "IssueGroupCont",components:{ ContTextarea,ContUpFile }})
export default class IssueGroupCont extends Vue implements IssueGroupContType{
    addressIcon = require("@/views/Group/Img/address.png")
    rightIcon = require("@/assets/icon/Basics/Right.png")

    get getAddress(){
        let addressData = IssueGroupStore.getIssueGroupAddress
        if ( addressData.state == "ok" ){
            if ( addressData.showSiteStr ){
                return addressData.showSiteStr
            }else if ( addressData.address ){
                return addressData.address
            }else {
                return `${ addressData.city }${ addressData.district }${ addressData.township }`
            }
        }else{
            return "请选择您的位置信息"
        }
    }
}
