











import {Vue, Component, Prop, Watch, Emit} from "vue-property-decorator";
import FooterInputType from "./indexType";

@Component({name: "FooterInput"})
export default class FooterInput extends Vue implements FooterInputType{
    value = ""

    @Prop(String)
    title!:string
    get getTitle(){ return this.title || '标题' }

    @Prop(String)
    place!:string
    get getPlace(){ return this.place || '请输入内容' }

    @Prop(Object)
    other!:any
    get getOther(){ return this.other || {} }

    @Prop(String)
    type!:string
    get getType(){ return this.type || 'text' }

    @Prop(String)
    state!:string
    get getState(){ return this.state || 'input' }

    @Prop(Boolean)
    reset!:boolean
    @Watch("reset")
    changeReset(newVal:boolean){
        this.value = ""
    }

    @Prop([Number,String])
    data!:string|number
    @Watch("data")
    changeData(newVal:string|number){
        this.value = newVal as string
    }

    @Emit("changeValue")
    @Watch("value")
    changeValue(newVal:string){
        return {
            data:newVal,
            other:this.getOther
        }
    }
}
